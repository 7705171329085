<template>
  <div class="notice-container">
    <el-row >
      <el-col class="notice-left">
        <div class="content">
          <div class="notice-breadcrumb">
            <div>首页</div>
            <div>></div>
            <div class="last-text">网站公告</div>
          </div>
          <template v-if="this.currentPage1 == 1">
            <div>
              <div class="notice-content-title">
                <div>06-<span class="notice-date">01</span></div>
                <div class="pointer">
                  <router-link to="/notice14" tag="span">童心飞扬 快乐六一</router-link>
                </div>
              </div>
              <div class="notice-content">
                <div style="font-size: 14px">2022</div>
                <div style="font-size: 16px">童年，是一只载着无数快乐的小船；童年，是一颗甜甜的糖果；童年，是一座美丽的秘密花园。杭州里德致力于提升员工幸福感，创办托班为公司员工解决孩子托管问题。6月1日晚上，里德童……</div>
              </div>
            </div>
            <div style="margin-top: 40px">
              <div class="notice-content-title">
                <div>05-<span class="notice-date">01</span></div>
                <div class="pointer">
                  <router-link to="/notice13" tag="span">知最美劳动者-记客服团队</router-link>
                </div>
              </div>
              <div class="notice-content">
                <div style="font-size: 14px">2022</div>
                <div style="font-size: 16px">小鸟为天空而歌，蜜蜂为花朵而歌，今天我们为劳动者而歌。在里德有这样一支队伍--客服，他们不畏艰辛，脚踏实地，毫无怨言，不求荣誉。2021年里德持续承接了青海西宁、西藏阿里、新……</div>
              </div>
            </div>
            <div style="margin-top: 40px">
              <div class="notice-content-title">
                <div>04-<span class="notice-date">26</span></div>
                <div class="pointer">
                  <router-link to="/notice12" tag="span">【职业健康】一切为了员工健康，企业开展《职业病防治法》宣传周活动</router-link>
                </div>
              </div>
              <div class="notice-content">
                <div style="font-size: 14px">2022</div>
                <div style="font-size: 16px">为助力健康亚运建设，保障劳动者职业健康权益4月25日-5月1日是全国《职业病防治法》第20个宣传周，为深入贯彻《职业病防治法》，进一步提高员工对职业病危害性的认识，加强自我保护……</div>
              </div>
            </div>
            <div style="margin-top: 40px">
              <div class="notice-content-title">
                <div>04-<span class="notice-date">05</span></div>
                <div class="pointer">
                  <router-link to="/notice11" tag="span">里德工业园区储能光伏备电一体化项目正式投入运行</router-link>
                </div>
              </div>
              <div class="notice-content">
                <div style="font-size: 14px">2022</div>
                <div style="font-size: 16px">2022年3月24日，杭州里德通信有限公司桐庐生产基地园区储能光伏备电一体化示范项目正式投入运行，该项目是浙江省首例县域储能+光伏+备电一体化工业园区示范工程，标志着里德在推动……</div>
              </div>
            </div>
            <div style="margin-top: 40px">
              <div class="notice-content-title">
                <div>03-<span class="notice-date">08</span></div>
                <div class="pointer">
                  <router-link to="/notice10" tag="span">春风摇曳女神节，里德暖心祝美丽</router-link>
                </div>
              </div>
              <div class="notice-content">
                <div style="font-size: 14px">2022</div>
                <div style="font-size: 16px">“芳菲消息到，杏梢红”，在这春回大地、草长莺飞的季节，又一个“三八女神节”翩然而至，为庆祝这一节日，公司一早就准备好了精美的礼品，为我们送上节日的祝福！ 让我们在这个特殊……</div>
              </div>
            </div>
            <div style="margin-top: 40px">
              <div class="notice-content-title">
                <div>01-<span class="notice-date">10</span></div>
                <div class="pointer">
                  <router-link to="/notice9" tag="span">春节放假通知</router-link>
                </div>
              </div>
              <div class="notice-content">
                <div style="font-size: 14px">2022</div>
                <div style="font-size: 16px">尊敬的杭州里德通信、里德通信科技（重庆）用户：您好！2022年春节将至，里德放假具体安排如下：杭州里德通信： 2022年01月25日（腊月二十三）—2022年02月09日（正月初九）放假……</div>
              </div>
            </div>

            <div style="margin-top: 40px">
              <div class="notice-content-title">
                <div>12-<span class="notice-date">31</span></div>
                <div class="pointer">
                  <router-link to="/notice8" tag="span">新年寄语</router-link>
                </div>
              </div>
              <div class="notice-content">
                <div style="font-size: 14px">2021</div>
                <div style="font-size: 16px">金牛奋蹄奔大道 玉虎添翼舞新春。我们告别充满挑战、奋发有为的2021年，迎来充满希望、进取的2022年。在此，我谨代表杭州里德公司管理团队向过去一年来为公司的发展默默奉献的全体家……</div>
              </div>
            </div>

            <div style="margin-top: 40px">
              <div class="notice-content-title">
                <div>12-<span class="notice-date">31</span></div>
                <div class="pointer">
                  <router-link to="/notice7" tag="span">2021年自主产品年终经营研讨会</router-link>
                </div>
              </div>
              <div class="notice-content">
                <div style="font-size: 14px">2021</div>
                <div style="font-size: 16px">岁月不居，时节如流。里德通信2021年研发及市场总结大会于12月30号在桐庐总部如期举行，研发及市场家人们汇聚一堂，回顾一年工作，展望来年规划。 2021年实属不易，里德自主产品经营……</div>
              </div>
            </div>
          </template>
          <template v-else-if="this.currentPage1 == 2">
            <div>
              <div class="notice-content-title">
                <div>12-<span class="notice-date">22</span></div>
                <div class="pointer">
                  <router-link to="/notice6" tag="span">以“球”会友，勇往直前</router-link>
                </div>
              </div>
              <div class="notice-content">
                <div style="font-size: 14px">2021</div>
                <div style="font-size: 16px">为丰富广大员工的文体娱乐生活，增强团队协作精神和公司凝聚力，公司开展了多项友谊联赛。 “小小银球连四海，诚伴友谊走天涯”，精彩的乒乓球比赛，吸引了众多同事驻足观看。有的选……</div>
              </div>
            </div>
            <div style="margin-top: 40px">
              <div class="notice-content-title">
                <div>12-<span class="notice-date">13</span></div>
                <div class="pointer">
                  <router-link to="/notice5" tag="span">团结防疫，人人有责！</router-link>
                </div>
              </div>
              <div class="notice-content">
                <div style="font-size: 14px">2021</div>
                <div style="font-size: 16px">根据国内疫情的发展，和近期气温变化较大，为了确保您的安全做到防患于未然，行政管理部温馨提示各位员工注意以下事项：一、疫情防护 出门佩戴口罩、勤洗手、常通风，保持安全社交……</div>
              </div>
            </div>
            <div style="margin-top: 40px">
              <div class="notice-content-title">
                <div>09-<span class="notice-date">04</span></div>
                <div class="pointer">
                  <router-link to="/notice4" tag="span">关爱员工—公司解决员工子女入学和接送问题</router-link>
                </div>
              </div>
              <div class="notice-content">
                <div style="font-size: 14px">2021</div>
                <div style="font-size: 16px">孩子是祖国的未来，也是民族的希望，让他们健康快乐地成长、接受良好的教育是每个家长的期望。公司的员工来自五湖四海，一年难得和自己的子女见一次，所以能把孩子接到身边生活是不少……</div>
              </div>
            </div>

            <div style="margin-top: 40px">
              <div class="notice-content-title">
                <div>01-<span class="notice-date">22</span></div>
                <div class="pointer">
                  <router-link to="/notice1" tag="span">开展“十四五”规划——里德奋勇争先</router-link>
                </div>
              </div>
              <div class="notice-content">
                <div style="font-size: 14px">2021</div>
                <div style="font-size: 16px">2021年1月21日下午，由县委书记方毅，副县长王先勇，县委常委、管委会书记施建华，管委会党委副书记、主任周跃群带队桐庐县发改局、县经信局、县科技局、县财政局、县商……</div>
              </div>
            </div>

            <div style="margin-top: 40px">
              <div class="notice-content-title">
                <div>09-<span class="notice-date">25</span></div>
                <div class="pointer">
                  <router-link to="/notice2" tag="span">杭州里德荣获“两轮车锂电池保护板技术领导品牌”称号</router-link>
                </div>
              </div>
              <div class="notice-content">
                <div style="font-size: 14px">2020</div>
                <div style="font-size: 16px">2020年9月25日，以“新思维 新模式 新技术 新生态 ”为主题的第二届中国（锂电）电动自行车产业生态大会暨轻型动力锂电技术高峰论坛在无锡隆重举行。会议期间杭州里德荣获……</div>
              </div>
            </div>

            <div style="margin-top: 40px">
              <div class="notice-content-title">
                <div>04-<span class="notice-date">28</span></div>
                <div class="pointer">
                  <router-link to="/notice3" tag="span">杭州里德信息化数据对接项目正式启动</router-link>
                </div>
              </div>
              <div class="notice-content">
                <div style="font-size: 14px">2020</div>
                <div style="font-size: 16px">4月23日下午，里德与客户信息化数据对接项目启动会议在杭州成功举行，里德组建了以查德芳副总为组长的里德生产数据对接小组，负责项目中里德资源协调及进度管控。会议本……</div>
              </div>
            </div>
          </template>

          <el-pagination background layout="prev, pager, next, jumper" :total="20" @current-change="handleCurrentChange"
            style="text-align: right;margin-top: 20px">
          </el-pagination>

        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentPage1: 1,
    }
  },
  methods: {
    handleCurrentChange(val) {
      this.currentPage1 = val
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
.notice-container {
  padding: 20px gap() !important;
  background-color: #fff;
}

.notice-left {

  .content {
    margin-right: 12px;
    padding: 16px 0 40px;
    font-size: 16px;
  }

  p {
    text-indent: 2em;
    line-height: 1.5;
  }
}

.notice-breadcrumb {
  display: flex;
  font-size: 14px;
  margin-bottom: 20px;

  .last-text {
    color: #a6a6a6;
  }
}

.notice-date {
  border-bottom: 1px solid #da261d;
}

.notice-content-title {
  font-size: 20px;
  display: flex;
  margin-bottom: 10px;

  div:first-of-type {
    width: 56px;
  }

  div:last-of-type {
    font-weight: bold;
    margin-left: 24px;
  }
}

.notice-content {
  display: flex;
  padding-bottom: 10px;
  border-bottom: 1px solid #d4d4d4;

  div:first-of-type {
    width: 56px;
    text-align: right;
  }

  div:last-of-type {
    margin-left: 24px;
  }
}
</style>
